// This hook is tweaked from Ant Design's menu component
// https://github.com/ant-design/ant-design/blob/master/components/menu/hooks/useItems.tsx
// It converts the items array to React nodes using our components

import { useMemo } from 'react';

import { ItemType } from 'rc-menu/lib/interface';

import MenuDivider from '../components/MenuDivider';
import MenuItem from '../components/MenuItem';
import MenuItemGroup from '../components/MenuItemGroup';
import SubMenu from '../components/SubMenu';

const convertItemsToNodes = (items: ItemType[]) =>
  (items || [])
    .map((opt, index) => {
      if (opt && typeof opt === 'object') {
        const { label, children, key, type, ...restProps } = opt as any;
        const mergedKey = key ?? `tmp-${index}`;

        if (children || type === 'group') {
          if (type === 'group') {
            return (
              <MenuItemGroup key={mergedKey} {...restProps} title={label}>
                {convertItemsToNodes(children)}
              </MenuItemGroup>
            );
          }

          return (
            <SubMenu key={mergedKey} {...restProps} title={label}>
              {convertItemsToNodes(children)}
            </SubMenu>
          );
        }

        if (type === 'divider') {
          return <MenuDivider key={mergedKey} {...restProps} />;
        }

        return (
          <MenuItem key={mergedKey} {...restProps}>
            {label}
          </MenuItem>
        );
      }

      return null;
    })
    .filter((opt) => opt);

export const useItems = (items?: ItemType[]) =>
  useMemo(() => {
    if (!items) {
      return items;
    }

    return convertItemsToNodes(items);
  }, [items]);
