import { forwardRef } from 'react';

import classNames from 'classnames';

import { DIVIDER_CLASS } from '../../utils/constants';
import * as Styled from './Divider.styles';
import { IDividerProps } from './Divider.types';

const Divider = forwardRef<HTMLHRElement, IDividerProps>(
  ({ className, size = 'medium', direction = 'horizontal', intensity = 'medium', gutter = 'none', ...rest }, ref) => (
    <Styled.Divider
      className={classNames(DIVIDER_CLASS, className)}
      size={size}
      direction={direction}
      intensity={intensity}
      gutter={gutter}
      ref={ref}
      {...rest}
    />
  ),
);

export default Divider;
