import { ButtonSize, ButtonVariant } from './Button.types';

type ButtonConstsType = {
  padding: {
    [size in ButtonSize]: {
      [variant in ButtonVariant]: string;
    };
  };
  icons: {
    [size in ButtonSize]: string;
  };
  sizes: {
    [size in ButtonSize]: string;
  };
};

export const buttonConsts: ButtonConstsType = {
  padding: {
    xsmall: {
      filled: '8px',
      transparent: '4px',
      outlined: '7px',
    },
    small: {
      filled: '12px',
      transparent: '6px',
      outlined: '10px',
    },
    medium: {
      filled: '16px',
      transparent: '8px',
      outlined: '15px',
    },
    large: {
      filled: '24px',
      transparent: '12px',
      outlined: '23px',
    },
  },
  icons: {
    xsmall: '16px',
    small: '16px',
    medium: '20px',
    large: '24px',
  },
  sizes: {
    xsmall: '24px',
    small: '32px',
    medium: '40px',
    large: '48px',
  },
};
