import { CardPadding, CardSize } from './Card.types';

type CardConstsType = {
  [sizes in CardSize]: {
    [paddings in CardPadding]: {
      padding: string;
    };
  };
};

export const cardConsts: CardConstsType = {
  small: {
    tight: {
      padding: '16px',
    },
    generous: {
      padding: '24px',
    },
  },
  medium: {
    tight: {
      padding: '20px',
    },
    generous: {
      padding: '32px',
    },
  },
  large: {
    tight: {
      padding: '24px',
    },
    generous: {
      padding: '40px',
    },
  },
};
