import { FC } from 'react';

import { Add, ChevronSmallDown, ChevronSmallUp, Minus } from '../Icon/components';
import { IIconProps } from '../Icon/Icon.types';
import { AccordionIconSetType, AccordionSize } from './Accordion.types';

type AccordionConstsType = {
  [size in AccordionSize]: {
    minHeight: number;
    bodyPadding: string;
    iconSize: string;
  };
};

export const accordionConsts: AccordionConstsType = {
  small: {
    minHeight: 40,
    bodyPadding: '0 20px 20px',
    iconSize: '20px',
  },
  medium: {
    minHeight: 56,
    bodyPadding: '0 20px 20px',
    iconSize: '20px',
  },
  large: {
    minHeight: 72,
    bodyPadding: '0 24px 24px',
    iconSize: '24px',
  },
};

type AccordionIconType = {
  [iconSet in AccordionIconSetType]: {
    active: FC<IIconProps>;
    inactive: FC<IIconProps>;
  };
};

export const AccordionIcon: AccordionIconType = {
  chevron: {
    active: ChevronSmallUp,
    inactive: ChevronSmallDown,
  },
  plus: {
    active: Minus,
    inactive: Add,
  },
};
