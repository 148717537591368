import { ReactNode } from 'react';

import * as Styled from './HeaderAccordion.styles';
import { IHeaderAccordion } from './HeaderAccordion.types';

const HeaderAccordion = ({ header, label }: IHeaderAccordion) => {
  const renderItem = (children: string | ReactNode, isLabel = false) => {
    if (typeof children === 'string') {
      if (isLabel) {
        return <Styled.Label>{children}</Styled.Label>;
      }

      return <span>{children}</span>;
    }

    return children;
  };

  return (
    <Styled.Container>
      {renderItem(header)}
      {renderItem(label, true)}
    </Styled.Container>
  );
};

export default HeaderAccordion;
