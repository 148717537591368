import { PectenPadding, PectenSize } from './Pecten.types';

type PectenSizesProps = {
  [size in PectenSize]: string;
};
export const pectenSizes: PectenSizesProps = {
  small: '16px',
  medium: '40px',
  large: '72px',
  xlarge: '100px',
};

type PectenPaddingsProps = {
  [size in PectenSize]: {
    [padding in PectenPadding]: string;
  };
};
export const pectenPaddings: PectenPaddingsProps = {
  small: {
    none: '0',
    minimum: '4px',
    optimal: '8px',
  },
  medium: {
    none: '0',
    minimum: '12px',
    optimal: '20px',
  },
  large: {
    none: '0',
    minimum: '21px',
    optimal: '36px',
  },
  xlarge: {
    none: '0',
    minimum: '30px',
    optimal: '50px',
  },
};
