import { css } from 'styled-components';

import { MENU_CLASS } from '../../../utils/constants';
import { menuConsts } from '../Menu.consts';
import { StyledMenuProps } from '../Menu.types';

export const menuLabelStyle = css<StyledMenuProps>`
  ${({ size, inlineCollapsed }) => {
    const iconSize = `${menuConsts[size].iconSize}px`;
    const labelPadding = `${menuConsts[size].labelPadding}px`;

    return css`
      .${MENU_CLASS}-label {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        border-radius: 2px;
        padding: 6px;
        margin: -6px;
        transition: background-color 0.3s ease-out;
        flex-grow: 1;
        min-width: 0;

        .${MENU_CLASS}-label-text {
          transition: opacity 0.3s ease-out;
          padding: ${labelPadding} 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .${MENU_CLASS}-label-icon {
          width: ${iconSize};
          height: ${iconSize};
          flex-shrink: 0;
        }
      }

      /* Top level */
      &.${MENU_CLASS} {
        > .${MENU_CLASS}-submenu
          > .${MENU_CLASS}-submenu-title
          > .${MENU_CLASS}-submenu-title-content,
          > .${MENU_CLASS}-item {
          > .${MENU_CLASS}-label > .${MENU_CLASS}-label-text {
            white-space: nowrap;
          }
        }
      }

      ${inlineCollapsed &&
      css`
        &.${MENU_CLASS}-inline-collapsed {
          > .${MENU_CLASS}-submenu
            > .${MENU_CLASS}-submenu-title
            > .${MENU_CLASS}-submenu-title-content,
            > .${MENU_CLASS}-item {
            > .${MENU_CLASS}-label > .${MENU_CLASS}-label-text {
              opacity: 0;
              text-overflow: clip;
              white-space: nowrap;
            }
          }
        }
      `}
    `;
  }}
`;
