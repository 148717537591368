import styled, { css } from 'styled-components';

import { avatarConsts } from './Avatar.consts';
import { IAvatarProps } from './Avatar.types';

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
`;

const NoImageCircleWrapper = styled.div<Required<Pick<IAvatarProps, 'size'>>>`
  ${({ size, theme }) => css`
    ${(size === 'small' || size === 'medium') && theme.heading[5].bold}
    ${size === 'large' && theme.heading[3].demi}

    display: flex;
    justify-content: center;
    align-items: center;
    width: ${avatarConsts[size].avatar}px;
    height: ${avatarConsts[size].avatar}px;
    border-radius: 100%;
    line-height: initial;
    text-align: center;
    white-space: nowrap;
    color: ${theme.text.onFocus.strong};
    background-color: ${theme.focus.strong};

    svg {
      path {
        fill: ${theme.text.onFocus.strong};
      }
    }
  `}
`;

const NoImageCircle = styled.div`
  display: flex;
  font-size: 100%;
`;

const Image = styled.img<Required<Pick<IAvatarProps, 'size'>>>`
  max-width: 100%;
  border-radius: 100%;
  object-fit: cover;

  ${({ size }) =>
    size &&
    `
    width: ${avatarConsts[size].avatar}px;
    height: ${avatarConsts[size].avatar}px;
  `}
`;

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const Label = styled.p<IAvatarProps>`
  ${({ theme, size, additionalText }) => css`
    margin: 0;

    ${additionalText &&
    additionalText.length > 0 &&
    css`
      ${size === 'large' && theme.label.medium.bold}
      ${(size === 'small' || size === 'medium') && theme.label.small.bold}
    `}

    ${(!additionalText || (additionalText && additionalText.length === 0)) &&
    css`
      ${(size === 'large' || size === 'medium') && theme.label.medium.bold}
      ${size === 'small' && theme.label.small.bold}
    `}
    
    color: ${theme.text.onSurface.strong};
  `}
`;

const AdditionalText = styled.p<Required<Pick<IAvatarProps, 'size'>>>`
  ${({ size, theme }) => css`
    margin: ${size === 'large' ? '4px' : '0'} 0 0;

    ${size === 'large' && theme.label.medium.normal}
    ${(size === 'small' || size === 'medium') && theme.label.small.normal}

    color: ${theme.text.onSurface.subtle};
  `}
`;

const AvatarDropdown = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

export {
  AvatarWrapper,
  Avatar,
  NoImageCircleWrapper,
  NoImageCircle,
  Image,
  TextBox,
  Label,
  AdditionalText,
  AvatarDropdown,
};
