import { MenuSize } from './Menu.types';

type MenuConstsType = {
  [size in MenuSize]: {
    iconSize: number;
    padding: number;
    indent: number;
    labelPadding: number;
  };
};

export const indicatorWidth = 3;
export const smallMenuIconSize = 16;
export const mediumMenuIconSize = 20;
export const largeMenuIconSize = 24;

export const menuConsts: MenuConstsType = {
  small: {
    iconSize: smallMenuIconSize,
    padding: 8,
    indent: 14,
    labelPadding: 0,
  },
  medium: {
    iconSize: mediumMenuIconSize,
    padding: 10,
    indent: 14,
    labelPadding: 2,
  },
  large: {
    iconSize: largeMenuIconSize,
    padding: 12,
    indent: 14,
    labelPadding: 2,
  },
};
