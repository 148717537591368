import { AvatarSize } from './Avatar.types';

type AvatarConstsType = {
  [size in AvatarSize]: {
    avatar: number;
    icon: number;
  };
};

export const avatarConsts: AvatarConstsType = {
  small: {
    avatar: 28,
    icon: 20,
  },
  medium: {
    avatar: 36,
    icon: 24,
  },
  large: {
    avatar: 44,
    icon: 28,
  },
};

export const altDefaultText = 'Avatar';
