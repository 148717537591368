import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { BUTTON_CLASS } from '../../utils/constants';
import * as Styled from './Button.styles';
import { IButtonProps } from './Button.types';
import ButtonContent from './components/ButtonContent/ButtonContent';

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      className = '',
      children,
      variant = 'filled',
      size = 'medium',
      icon,
      iconOnly = false,
      iconPosition = 'left',
      sentiment,
      state,
      loading = false,
      alignment = 'edge',
      fullWidth = false,
      type = 'button',
      ...rest
    }: IButtonProps,
    ref,
  ) => (
    <Styled.Button
      className={classNames(BUTTON_CLASS, className)}
      size={size}
      variant={variant}
      iconOnly={iconOnly}
      iconPosition={iconPosition}
      sentiment={sentiment || state || 'default'}
      alignment={alignment}
      fullWidth={fullWidth}
      type={type}
      ref={ref}
      {...rest}
    >
      <ButtonContent
        variant={variant}
        sentiment={sentiment || state || 'default'}
        loading={loading}
        icon={icon}
        iconOnly={iconOnly}
        iconPosition={iconPosition}
      >
        {children}
      </ButtonContent>
    </Styled.Button>
  ),
);

export default Button;
