import { css } from 'styled-components';

import { MENU_CLASS } from '../../../utils/constants';
import { menuConsts } from '../Menu.consts';
import { StyledMenuProps } from '../Menu.types';
import { indicatorBottomStyle, indicatorPositionStyle } from './indicator.styles';

export const menuItemStyle = css<StyledMenuProps>`
  ${({ theme, size }) => {
    const verticalPadding = `${menuConsts[size].padding}px`;
    const leftPadding = `${menuConsts[size].indent}px`;
    const rightPadding = `${menuConsts[size].indent}px`;

    return css`
      .${MENU_CLASS}-item {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: ${verticalPadding} ${rightPadding} ${verticalPadding} ${leftPadding};

        &.${MENU_CLASS}-item-active {
          .${MENU_CLASS}-label {
            background-color: ${theme.focus.subtle};
          }

          &:focus-visible {
            .${MENU_CLASS}-label {
              background-color: transparent;
            }
          }
        }

        &:focus-visible {
          outline: none;

          .${MENU_CLASS}-label {
            border-radius: 2px;
            outline: 3px solid ${theme.focus.medium};
          }
        }
      }

      /* Vertical and horizontal popup  */
      .${MENU_CLASS}-vertical .${MENU_CLASS}-item.${MENU_CLASS}-item-selected {
        background-color: ${theme.background.section};

        &:after {
          ${indicatorPositionStyle}
        }
      }

      /* Inline and vertical top level */
      &.${MENU_CLASS}-inline, &.${MENU_CLASS}-vertical {
        .${MENU_CLASS}-item.${MENU_CLASS}-item-selected {
          background-color: ${theme.background.section};

          &:after {
            ${indicatorPositionStyle}
          }
        }
      }

      /* Horizontal */
      &.${MENU_CLASS}-horizontal .${MENU_CLASS}-item.${MENU_CLASS}-item-selected {
        background-color: ${theme.background.section};

        &:after {
          ${indicatorBottomStyle}
        }
      }
    `;
  }}
`;
