import { HeadingLevel } from '../Text/components/Heading/Heading.types';
import { LabelSize } from '../Text/components/Label/Label.types';
import { ModalSize, ModalPadding } from './Modal.types';

type ModalConstsProps = {
  [size in ModalSize]: {
    titleLevel: HeadingLevel;
    descriptionSize: LabelSize;
    titleAndDescriptionGap: string;
    titleBarPadding: {
      [padding in ModalPadding]: string;
    };
    actionBarPadding: {
      [padding in ModalPadding]: string;
    };
    bodyPadding: {
      [padding in ModalPadding]: string;
    };
    closeButton: {
      width?: string;
      height?: string;
      position: {
        [padding in ModalPadding]: {
          top: string;
          right: string;
        };
      };
    };
  };
};

export const modalConsts: ModalConstsProps = {
  small: {
    titleLevel: 4,
    descriptionSize: 'xsmall',
    titleAndDescriptionGap: '2px',
    titleBarPadding: { tight: '22px 55px 22px 16px', generous: '30px 55px 30px 24px' },
    actionBarPadding: { tight: '16px', generous: '24px' },
    bodyPadding: { tight: '16px', generous: '24px' },
    closeButton: {
      width: '28px',
      height: '32px',
      position: {
        tight: {
          top: '16px',
          right: '12px',
        },
        generous: {
          top: '24px',
          right: '20px',
        },
      },
    },
  },
  medium: {
    titleLevel: 3,
    descriptionSize: 'small',
    titleAndDescriptionGap: '4px',
    titleBarPadding: { tight: '28px 60px 28px 20px', generous: '40px 60px 40px 32px' },
    actionBarPadding: { tight: '20px', generous: '32px' },
    bodyPadding: { tight: '20px', generous: '32px' },
    closeButton: {
      width: '28px',
      height: '40px',
      position: {
        tight: {
          top: '20px',
          right: '16px',
        },
        generous: {
          top: '32px',
          right: '28px',
        },
      },
    },
  },
  large: {
    titleLevel: 2,
    descriptionSize: 'medium',
    titleAndDescriptionGap: '8px',
    titleBarPadding: { tight: '34px 70px 34px 24px', generous: '50px 60px 50px 40px' },
    actionBarPadding: { tight: '24px', generous: '40px' },
    bodyPadding: { tight: '24px', generous: '40px' },
    closeButton: {
      width: '36px',
      height: '48px',
      position: {
        tight: {
          top: '24px',
          right: '18px',
        },
        generous: {
          top: '40px',
          right: '34px',
        },
      },
    },
  },
};
