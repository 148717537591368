import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { AVATAR_CLASS } from '../../utils/constants';
import PersonSolid from '../Icon/components/PersonSolid';
import { altDefaultText, avatarConsts } from './Avatar.consts';
import * as Styled from './Avatar.styles';
import { IAvatarProps, IAvatarNoImageCircle, IAvatarAsText, IAvatarAsIcon, IAvatarAsImage } from './Avatar.types';
import AvatarDropdown from './components/AvatarDropdown/AvatarDropdown';

const NoImageCircle = ({ size, ariaLabel, children }: IAvatarNoImageCircle) => (
  <Styled.NoImageCircleWrapper size={size}>
    <Styled.NoImageCircle aria-label={ariaLabel}>{children}</Styled.NoImageCircle>
  </Styled.NoImageCircleWrapper>
);

const AsAbbreviation = ({ abbreviation, size, ariaLabel }: IAvatarAsText) => (
  <NoImageCircle size={size} ariaLabel={ariaLabel}>
    {abbreviation}
  </NoImageCircle>
);

const AsIcon = ({ size, ariaLabel }: IAvatarAsIcon) => {
  const iconSize = avatarConsts[size].icon;

  return (
    <NoImageCircle size={size}>
      <PersonSolid width={iconSize} height={iconSize} aria-label={ariaLabel} />
    </NoImageCircle>
  );
};

const AsImage = ({ src, alt, size }: IAvatarAsImage) => (
  <Styled.Image width={size} height={size} src={src} size={size} alt={alt} />
);

const Avatar = ({
  className,
  size = 'medium',
  src,
  alt = altDefaultText,
  abbreviation,
  label,
  additionalText,
  dropdownOverlay,
  ...rest
}: IAvatarProps): ReactElement => {
  const AvatarContent = () => (
    <Styled.AvatarWrapper>
      <Styled.Avatar className={classNames(AVATAR_CLASS, className)} {...rest}>
        {src && <AsImage size={size} src={src} alt={alt} />}
        {!src &&
          (abbreviation ? (
            <AsAbbreviation abbreviation={abbreviation} size={size} ariaLabel={rest['aria-label']} />
          ) : (
            <AsIcon size={size} ariaLabel={rest['aria-label']} />
          ))}
      </Styled.Avatar>
      {(label || additionalText) && (
        <Styled.TextBox>
          {label && (
            <Styled.Label size={size} additionalText={additionalText}>
              {label}
            </Styled.Label>
          )}
          {additionalText && <Styled.AdditionalText size={size}>{additionalText}</Styled.AdditionalText>}
        </Styled.TextBox>
      )}
    </Styled.AvatarWrapper>
  );

  return (
    <>
      {dropdownOverlay ? (
        <AvatarDropdown dropdownOverlay={dropdownOverlay}>
          <AvatarContent />
        </AvatarDropdown>
      ) : (
        <AvatarContent />
      )}
    </>
  );
};

export default Avatar;
