import { Theme } from '../../../providers/theme/theme';
import { buttonConsts } from '../Button.consts';
import { ButtonSize, ButtonSentiment, ButtonVariant } from '../Button.types';

export const getPadding = (iconOnly: boolean, size: ButtonSize, variant: ButtonVariant) =>
  !iconOnly && buttonConsts.padding[size][variant] && `0 ${buttonConsts.padding[size][variant]}`;

export const getAlignmentMargin = (size: ButtonSize, variant: ButtonVariant) =>
  buttonConsts.padding[size][variant] && `-${buttonConsts.padding[size][variant]}`;

export const getColor = (theme: Theme, variant: ButtonVariant, sentiment: ButtonSentiment) => {
  if (variant === 'transparent' || variant === 'outlined') {
    if (sentiment === 'positive' || sentiment === 'negative') {
      return theme.text.onSurface[sentiment];
    }

    return theme.text.onSurface.strong;
  }

  if (sentiment === 'positive' || sentiment === 'negative') {
    return theme.text.onSystem.strong[sentiment];
  }

  return theme.text.onPrimary.strong;
};

export const getColorOnHover = (theme: Theme, variant: ButtonVariant, sentiment: ButtonSentiment) => {
  if (sentiment === 'default' && (variant === 'transparent' || variant === 'outlined')) {
    return theme.text.onSurface.strong;
  }

  if (sentiment === 'positive' || sentiment === 'negative') {
    return theme.text.onSurface[sentiment];
  }

  return theme.text.onPrimary.strong;
};

export const getBackgroundColor = (theme: Theme, variant: ButtonVariant, sentiment: ButtonSentiment) => {
  if (variant === 'transparent' || variant === 'outlined') {
    return 'transparent';
  }
  if (sentiment === 'positive' || sentiment === 'negative') {
    return theme.system.strong[sentiment];
  }

  return theme.accent.primary.strong;
};

export const getBackgroundColorOnHover = (theme: Theme, variant: ButtonVariant, sentiment: ButtonSentiment) => {
  if (sentiment === 'positive' || sentiment === 'negative') {
    return theme.system.subtle[sentiment];
  }

  switch (variant) {
    case 'filled':
      return theme.accent.primary.medium;
    case 'transparent':
      return theme.focus.subtle;
    default:
      return 'none';
  }
};

export const getBackgroundColorWhenDisabled = (theme: Theme, variant: ButtonVariant) => {
  if (variant === 'filled') {
    return theme.background.inactive;
  }

  return 'none';
};

export const getBorderColor = (theme: Theme, variant: ButtonVariant, sentiment: ButtonSentiment) => {
  if (variant === 'transparent' || variant === 'filled') {
    return 'transparent';
  }
  if (sentiment === 'negative' || sentiment === 'positive') {
    return theme.system.strong[sentiment];
  }

  return theme.border.medium;
};

export const getBorderColorOnHover = (theme: Theme, variant: ButtonVariant, sentiment: ButtonSentiment) => {
  if (sentiment === 'default' && variant === 'outlined') {
    return theme.focus.strong;
  }

  return 'none';
};

export const getBorderColorWhenDisabled = (theme: Theme, variant: ButtonVariant) => {
  if (variant === 'outlined') {
    return theme.border.subtle;
  }

  return 'none';
};
