import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const Label = styled.span`
  ${({ theme }) =>
    css`
      ${theme.label.medium.normal};
      color: ${theme.text.onSurface.subtle};
    `};
`;

export { Container, Label };
