import tinyColor from 'tinycolor2';

import { Theme } from '../providers/theme/theme';
import * as Types from '../types/types';

export const getColorBySentiment = (sentiment: Types.Sentiments | Types.DefaultSentiment, theme: Theme): string => {
  switch (sentiment) {
    case 'positive':
      return theme.system.strong.positive;
    case 'negative':
      return theme.system.strong.negative;
    case 'warning':
      return theme.system.strong.warning;
    case 'information':
      return theme.system.strong.information;
    case 'default':
      return theme.focus.strong;
    default:
      return theme.system.strong.neutral;
  }
};

export const getColorByVariant = (
  theme: Theme,
  sentiment: Types.Sentiments | Types.DefaultSentiment,
  variant: Types.FilledVariant | Types.SubtleVariant | Types.OutlinedVariant | Types.TransparentVariant,
) => {
  switch (variant) {
    case 'outlined':
    case 'transparent':
      if (sentiment === 'neutral') {
        return theme.text.onSurface.strong;
      }

      if (sentiment === 'default') {
        return theme.focus.strong;
      }

      return theme.text.onSurface[sentiment];
    case 'subtle':
      if (sentiment === 'default') {
        return theme.text.onSystem.subtle;
      }

      return theme.text.onSystem.subtle[sentiment];
    case 'filled':
    default:
      if (sentiment === 'default') {
        return theme.text.onSystem.strong.information;
      }

      return theme.text.onSystem.strong[sentiment];
  }
};

export const getBackgroundColorByVariant = (
  theme: Theme,
  sentiment: Types.Sentiments,
  variant: Types.FilledVariant | Types.SubtleVariant | Types.OutlinedVariant,
): string => {
  switch (variant) {
    case 'outlined':
      return 'transparent';
    case 'subtle':
      return theme.system.subtle[sentiment];
    case 'filled':
    default:
      return theme.system.strong[sentiment];
  }
};

export const getBorderColorByVariant = (
  theme: Theme,
  sentiment: Types.Sentiments,
  variant: Types.FilledVariant | Types.SubtleVariant | Types.OutlinedVariant,
): string => {
  switch (variant) {
    case 'outlined':
      if (sentiment === 'neutral') {
        return theme.border.medium;
      }

      if (sentiment === 'warning') {
        return theme.system.strong[sentiment];
      }

      return theme.text.onSurface[sentiment];
    case 'subtle':
      return theme.system.subtle[sentiment];
    case 'filled':
    default:
      return theme.system.strong[sentiment];
  }
};

export const rgba2rgb = (rawColor: string, rawBackground: string) => {
  const color = tinyColor(rawColor).toRgb();
  const background = tinyColor(rawBackground).toRgb();
  const alpha = color.a;

  color.r = Math.floor((1 - alpha) * background.r + alpha * color.r + 0.5);
  color.g = Math.floor((1 - alpha) * background.g + alpha * color.g + 0.5);
  color.b = Math.floor((1 - alpha) * background.b + alpha * color.b + 0.5);
  color.a = 1;

  return tinyColor(color).toRgbString();
};
