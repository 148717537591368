import { TableSize } from './Table.types';

type TableConstsProps = {
  [size in TableSize]: {
    height: string;
  };
};

export const tableConsts: TableConstsProps = {
  xsmall: {
    height: '32px',
  },
  small: {
    height: '40px',
  },
  medium: {
    height: '48px',
  },
  large: {
    height: '60px',
  },
};
