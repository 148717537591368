import { cloneElement, isValidElement, ReactElement } from 'react';

import { MENU_CLASS } from '../../../utils/constants';
import { IMenuProps } from '../Menu.types';

export type MenuLabelRequiredTypes = 'itemIcon' | 'className' | 'children' | 'size' | 'mode' | 'inlineCollapsed';

export interface IMenuLabelProps extends Pick<IMenuProps, MenuLabelRequiredTypes> {}

const MenuLabel = ({ children, itemIcon, ...rest }: IMenuLabelProps): ReactElement => {
  const iconProps = { className: `${MENU_CLASS}-label-icon` };

  return (
    <div className={`${MENU_CLASS}-label`} {...rest}>
      {isValidElement(itemIcon) ? cloneElement(itemIcon, iconProps) : null}
      <div className={`${MENU_CLASS}-label-text`}>{children}</div>
    </div>
  );
};

export default MenuLabel;
