import { forwardRef, ReactElement } from 'react';

import * as Styled from './Grid.styles';
import { ICellProps, IGridProps } from './Grid.types';

const Cell = forwardRef<HTMLDivElement, ICellProps>(
  ({ children, height, width, ...rest }, ref): ReactElement => (
    <Styled.Cell ref={ref} $height={height} $width={width} {...rest}>
      {children}
    </Styled.Cell>
  ),
);

const Grid = forwardRef<HTMLDivElement, IGridProps>(
  ({ children, height, width, ...rest }, ref): ReactElement => (
    <Styled.Grid ref={ref} $height={height} $width={width} {...rest}>
      {children}
    </Styled.Grid>
  ),
);

export default Object.assign(Grid, { Cell });
