import { LoadingSize, LoadingType } from './Loading.types';
import { Theme } from '../../providers/theme/theme';

type ProgressBarConstsColorsType = {
  color: {
    [state in LoadingType]: string;
  };
};

type LoadingConstsType = {
  [size in LoadingSize]: {
    viewBoxSize: number;
    strokeWidth: number;
  };
};

export const LoadingConstsColors = (theme: Theme): ProgressBarConstsColorsType => ({
  color: {
    default: theme.accent.primary.strong,
    primary: theme.text.onPrimary.strong,
    tertiary: theme.text.onTertiary.strong,
  },
});

export const loadingConsts: LoadingConstsType = {
  xsmall: {
    viewBoxSize: 16,
    strokeWidth: 2,
  },
  small: {
    viewBoxSize: 20,
    strokeWidth: 2,
  },
  medium: {
    viewBoxSize: 30,
    strokeWidth: 3,
  },
  large: {
    viewBoxSize: 40,
    strokeWidth: 4,
  },
};
