import styled from 'styled-components';

const ButtonContentLoader = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin: unset;
  }
`;

const ButtonContent = styled.span<{ visibility: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ visibility }) => visibility};
`;

export { ButtonContent, ButtonContentLoader };
