import styled, { css } from 'styled-components';

import { textAreaConsts, textAreaWrapperConsts } from './TextArea.consts';
import { ITextAreaProps } from './TextArea.types';

type TextAreaWrapperPropsRequired = 'size' | 'iconPosition' | 'disabled' | 'value';
const TextAreaWrapper = styled.div<Required<Pick<ITextAreaProps, TextAreaWrapperPropsRequired>>>`
  display: inline-block;
  position: relative;
  width: 100%;

  ${({ iconPosition, size, disabled, value, theme }) => css`
    svg {
      width: ${textAreaWrapperConsts.iconSize[size]};
      height: ${textAreaWrapperConsts.iconSize[size]};
      position: absolute;
      left: ${iconPosition === 'left' ? textAreaWrapperConsts.iconOffset[size] : null};
      right: ${iconPosition === 'right' ? textAreaWrapperConsts.iconOffset[size] : null};
      top: ${textAreaWrapperConsts.iconOffset[size]};

      path {
        ${disabled &&
        `
          fill: ${theme.text.onSurface.inactive}
        `}

        ${!disabled &&
        value &&
        `
          fill: ${theme.text.onSurface.strong}
        `}
      
        ${!disabled &&
        !value &&
        `
          fill: ${theme.text.onSurface.subtle}
        `}
      }
    }
  `}
`;

type TextAreaPropsRequired = 'invalid' | 'size' | 'icon' | 'iconPosition' | 'resize';
const TextArea = styled.textarea<Required<Pick<ITextAreaProps, TextAreaPropsRequired>>>`
  ${({ iconPosition, size, invalid, icon, disabled, resize, theme }) => css`
    ${(size === 'small' || size === 'medium') && theme.paragraph.small.normal};
    ${size === 'large' && theme.paragraph.medium.normal};
    border: 1px solid ${invalid ? theme.system.strong.negative : theme.border.medium};
    border-radius: 2px;
    width: 100%;
    color: ${theme.text.onSurface.strong};
    background-color: ${theme.background.surface};
    padding: ${icon ? textAreaConsts.paddingWithIcon[size][iconPosition] : textAreaConsts.paddingWithoutIcon[size]};
    transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    resize: ${resize};

    &::-webkit-input-placeholder,
    &::placeholder {
      color: ${disabled ? theme.text.onSurface.inactive : theme.text.onSurface.subtle};
    }

    &:-ms-input-placeholder {
      color: ${disabled ? theme.text.onSurface.inactive : theme.text.onSurface.subtle};
    }

    &:hover {
      ${!invalid &&
      css`
        border-color: ${theme.focus.strong};
      `}
    }

    &:focus {
      ${!invalid &&
      css`
        border: 1px solid ${theme.focus.strong};
      `}
      outline: 0;
      box-shadow: 0 0 0 3px ${theme.focus.medium};
    }

    &:disabled {
      background-color: ${theme.background.inactive};
      color: ${theme.text.onSurface.inactive};
      border-color: ${theme.border.subtle};
      cursor: not-allowed;
    }
  `}
`;

export { TextArea, TextAreaWrapper };
