import { RadioButtonSize } from './RadioButton.types';

type RadioButtonConstsType = {
  [size in RadioButtonSize]: {
    size: number;
    spacing: number;
    innerCircleSize: number;
  };
};

export const radioButtonConsts: RadioButtonConstsType = {
  small: {
    size: 16,
    spacing: 8,
    innerCircleSize: 6,
  },
  medium: {
    size: 24,
    spacing: 10,
    innerCircleSize: 8,
  },
  large: {
    size: 28,
    spacing: 12,
    innerCircleSize: 10,
  },
};
