import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { NAV_BAR_CLASS } from '../../utils/constants';
import AvatarArea from './components/AvatarArea/AvatarArea';
import PectenArea from './components/PectenArea/PectenArea';
import * as Styled from './NavBar.styles';
import { INavBarProps } from './NavBar.types';

const NavBar = forwardRef<HTMLElement, INavBarProps>(
  (
    {
      className,
      orientation = 'horizontal',
      float = true,
      children,
      pectenArea = 'default',
      avatarArea,
      avatarProps,
      ...rest
    },
    ref,
  ): ReactElement => (
    <Styled.NavBar
      as={orientation === 'vertical' ? 'nav' : 'header'}
      className={classNames(NAV_BAR_CLASS, className)}
      $orientation={orientation}
      $float={float}
      ref={ref}
      {...rest}
    >
      {pectenArea !== 'none' && <PectenArea pectenArea={pectenArea} orientation={orientation} />}
      <Styled.MiddleArea $orientation={orientation}>{children}</Styled.MiddleArea>
      {(avatarArea || avatarProps) && (
        <AvatarArea avatarArea={avatarArea} avatarProps={avatarProps} orientation={orientation} />
      )}
    </Styled.NavBar>
  ),
);

export default NavBar;
