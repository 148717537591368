import Loading from '../../../Loading/Loading';
import { LoadingType } from '../../../Loading/Loading.types';
import { IButtonContentProps } from '../../Button.types';
import * as Styled from './ButtonContent.styles';

const ButtonContent = ({
  loading,
  variant,
  sentiment,
  children,
  icon,
  iconOnly,
  iconPosition,
}: IButtonContentProps) => {
  const getLoaderType = (): LoadingType => {
    if (sentiment === 'default') {
      return variant === 'filled' ? 'primary' : 'default';
    }

    return variant === 'filled' ? 'tertiary' : 'primary';
  };

  return (
    <>
      <Styled.ButtonContentLoader>
        {loading && <Loading size="large" type={getLoaderType()} />}
      </Styled.ButtonContentLoader>
      <Styled.ButtonContent visibility={loading ? 'hidden' : 'visible'}>
        {iconPosition === 'left' && icon}
        {iconOnly ? null : children}
        {iconPosition === 'right' && icon}
      </Styled.ButtonContent>
    </>
  );
};

export default ButtonContent;
