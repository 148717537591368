import styled, { css } from 'styled-components';

import { cardConsts } from './Card.consts';
import { CardPadding, CardSize, ICardProps } from './Card.types';

type CardBodyProps = {
  size: CardSize;
  bodyPadding: boolean;
  padding: CardPadding;
};

const Card = styled.div<ICardProps & { as: string }>`
  ${({ theme, as }) => css`
    width: fit-content;
    border-radius: 2px;
    background-color: ${theme.background.surface};
    box-shadow: ${theme.elevation.level0};
    transition: box-shadow 0.3s ease;
    ${as === 'a' &&
    css`
      text-decoration: none;
      color: inherit;
      &:hover {
        box-shadow: ${theme.elevation.level1};
      }
    `}
  `}
`;

const CardBody = styled.div<CardBodyProps>`
  ${({ padding, size, bodyPadding, theme }) => css`
    display: grid;
    ${theme.paragraph.medium.normal}

    ${bodyPadding &&
    css`
      padding: ${cardConsts[size][padding].padding};
    `}
  `}
`;

export { Card, CardBody };
