import Avatar from '../../../Avatar/Avatar';
import * as Styled from '../../NavBar.styles';
import { NavBarLastAreaProps } from '../../NavBar.types';

const AvatarArea = ({ avatarArea, avatarProps, orientation }: NavBarLastAreaProps) => (
  <>
    {avatarArea && !avatarProps && <Styled.Area $orientation={orientation}>{avatarArea}</Styled.Area>}
    {avatarProps && (
      <Styled.Area $defaultPaddings $orientation={orientation}>
        <Avatar {...avatarProps} />
      </Styled.Area>
    )}
  </>
);

export default AvatarArea;
