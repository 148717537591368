import { ReactElement } from 'react';

import { MenuItem as RCMenuItem } from 'rc-menu';
import { MenuItemProps as RCMenuItemProps } from 'rc-menu/lib/MenuItem';

import { getString } from '../../../utils/textUtils';
import MenuLabel from './MenuLabel';

export interface IMenuItemProps extends RCMenuItemProps {}

const MenuItem = ({ children, itemIcon, ...rest }: IMenuItemProps): ReactElement => (
  <RCMenuItem aria-label={getString(children)} {...rest}>
    <MenuLabel itemIcon={itemIcon}>{children}</MenuLabel>
  </RCMenuItem>
);

export default MenuItem;
