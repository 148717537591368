import { forwardRef, ReactElement } from 'react';

import { TABS_CLASS } from '../../utils/constants';
import * as Styled from './Tabs.styles';
import { ITabsProps } from './Tabs.types';

const Tabs = forwardRef<HTMLDivElement, ITabsProps>(
  ({ className = '', size = 'large', emphasis = true, tabPosition = 'top', ...rest }, ref): ReactElement => (
    <Styled.Tabs
      className={className}
      prefixCls={TABS_CLASS}
      size={size}
      $emphasis={emphasis}
      tabPosition={tabPosition}
      ref={ref}
      {...rest}
    />
  ),
);

export default Tabs;
