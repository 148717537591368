import styled, { css } from 'styled-components';

import { buttonConsts } from './Button.consts';
import { ButtonAlignment, ButtonSize, ButtonSentiment, ButtonVariant } from './Button.types';
import {
  getAlignmentMargin,
  getBackgroundColor,
  getBackgroundColorOnHover,
  getBackgroundColorWhenDisabled,
  getBorderColor,
  getBorderColorOnHover,
  getBorderColorWhenDisabled,
  getColor,
  getColorOnHover,
  getPadding,
} from './utils/styleUtils';

type ButtonProps = {
  variant: ButtonVariant;
  size: ButtonSize;
  iconPosition: 'left' | 'right';
  iconOnly: boolean;
  sentiment: ButtonSentiment;
  disabled?: boolean;
  alignment?: ButtonAlignment;
  fullWidth?: boolean;
};

const CommonStyles = css<ButtonProps>`
  ${({ iconOnly, iconPosition, variant, size, fullWidth, sentiment, disabled, theme }) => css`
    ${(size === 'small' || size === 'xsmall') && theme.label.medium.bold};
    ${(size === 'medium' || size === 'large') && theme.label.large.bold};
    position: relative;
    border: 1px solid ${getBorderColor(theme, variant, sentiment)};
    color: ${getColor(theme, variant, sentiment)};
    border-radius: 2px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s, border 0.3s;

    &:focus-visible,
    &.test-simulate-focus {
      box-shadow: 0 0 0 3px ${theme.focus.medium};
      outline: 0;

      ${!disabled &&
      variant !== 'transparent' &&
      css`
        border-color: ${theme.focus.strong};
      `}
    }

    ${fullWidth &&
    css`
      width: 100%;
    `}

    min-height: ${buttonConsts.sizes[size]};

    ${iconOnly &&
    css`
      padding: 0;
      justify-content: center;
      width: ${buttonConsts.sizes[size]};
      height: ${buttonConsts.sizes[size]};
    `}

    svg {
      width: ${buttonConsts.icons[size]};
      height: ${buttonConsts.icons[size]};
      margin: ${!iconOnly && (iconPosition === 'right' ? '0 0 0 8px' : '0 8px 0 0')};
    }
  `}
`;

const Button = styled.button<ButtonProps>`
  ${CommonStyles}

  ${({ size, iconOnly, variant, sentiment, alignment, theme }) => css`
    padding: ${getPadding(iconOnly, size, variant)};
    background-color: ${getBackgroundColor(theme, variant, sentiment)};

    ${alignment === 'text' &&
    css`
      margin-left: ${getAlignmentMargin(size, variant)};
    `}

    svg path {
      fill: ${getColor(theme, variant, sentiment)};
    }

    &:disabled {
      background-color: ${getBackgroundColorWhenDisabled(theme, variant)};
      border-color: ${getBorderColorWhenDisabled(theme, variant)};
      color: ${theme.text.onSurface.inactive};
      cursor: not-allowed;
      pointer-events: none;

      svg path {
        fill: ${theme.text.onSurface.inactive};
      }
    }

    &:hover:not(:disabled) {
      background-color: ${getBackgroundColorOnHover(theme, variant, sentiment)};
      border-color: ${getBorderColorOnHover(theme, variant, sentiment)};
      color: ${getColorOnHover(theme, variant, sentiment)};

      svg path {
        fill: ${getColorOnHover(theme, variant, sentiment)};
      }
    }
  `}
`;

const ButtonLink = styled.a<ButtonProps>`
  ${CommonStyles}

  ${({ disabled, theme }) => css`
    color: ${theme.text.onSurface.link};
    text-decoration: none;

    svg {
      path {
        fill: ${theme.text.onSurface.link};
      }
    }

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: ${theme.text.onSurface.visited};

      svg {
        path {
          fill: ${theme.text.onSurface.visited};
        }
      }
    }

    &:focus-visible {
      color: ${theme.text.onSurface.strong};
      background-color: ${theme.focus.subtle};

      svg {
        path {
          fill: ${theme.text.onSurface.strong};
        }
      }
    }

    ${disabled &&
    css`
      &,
      &:visited,
      &:focus-visible {
        color: ${theme.text.onSurface.inactive};
        cursor: not-allowed;
        pointer-events: none;

        svg {
          path {
            fill: ${theme.text.onSurface.inactive};
          }
        }
      }
    `}
  `}
`;

export { Button, ButtonLink };
