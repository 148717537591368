type StepperConsts = {
  gapHorizontal: number;
  gapVertical: number;
  lineMinWidth: number;
};

export const stepperConsts: StepperConsts = {
  gapHorizontal: 20,
  gapVertical: 8,
  lineMinWidth: 20,
};
