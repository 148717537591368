import { ReactElement, useContext } from 'react';

import { SubMenu as RCSubMenu, SubMenuProps } from 'rc-menu';

import { MENU_CLASS } from '../../../utils/constants';
import { ChevronSmallDown } from '../../Icon/components';
import MenuContext from '../MenuContext';
import MenuLabel from './MenuLabel';

export interface ISubMenuProps extends SubMenuProps {}

const Title = ({ title, itemIcon }: ISubMenuProps): ReactElement => (
  <div className={`${MENU_CLASS}-submenu-title-content`}>
    <MenuLabel itemIcon={itemIcon}>{title}</MenuLabel>
    <ChevronSmallDown className={`${MENU_CLASS}-submenu-expand-icon`} />
  </div>
);

const SubMenu = ({ title, itemIcon, ...rest }: ISubMenuProps): ReactElement => {
  const { mode } = useContext(MenuContext);
  const popupOffset = mode === 'horizontal' ? [0, 0] : [8, 0];

  return <RCSubMenu popupOffset={popupOffset} title={<Title title={title} itemIcon={itemIcon} />} {...rest} />;
};

export default SubMenu;
