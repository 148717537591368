import Pecten from '../../../Pecten/Pecten';
import * as Styled from '../../NavBar.styles';
import { INavBarFirstAreaProps } from '../../NavBar.types';

const PectenArea = ({ pectenArea, orientation }: INavBarFirstAreaProps) => (
  <Styled.Area $defaultPaddings={pectenArea === 'default'} $orientation={orientation}>
    {pectenArea === 'default' ? <Pecten /> : pectenArea}
  </Styled.Area>
);

export default PectenArea;
