import styled, { css } from 'styled-components';

import { Z_INDEX } from '../../utils/constants';
import { NavBarOrientation } from './NavBar.types';

const NavBar = styled.header<{ $orientation: NavBarOrientation; $float: boolean }>`
  ${({ $orientation, $float, theme }) => css`
    z-index: ${Z_INDEX.NAVBAR};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${$orientation === 'horizontal' ? 'row' : 'column'};
    align-items: stretch;
    height: ${$orientation === 'vertical' ? '100%' : '64px'};
    width: ${$orientation === 'vertical' ? 'max-content' : '100%'};
    box-shadow: ${$float ? theme.elevation.level1 : null};
    background-color: ${theme.background.surface};

    ${$orientation === 'vertical' &&
    css`
      min-width: 64px;
    `}
  `}
`;

const MiddleArea = styled.div<{ $orientation: NavBarOrientation }>`
  ${({ $orientation }) => css`
    display: flex;
    flex: 1 1 0;
    flex-direction: ${$orientation === 'horizontal' ? 'row' : 'column'};
    justify-content: center;
    align-items: stretch;
    min-width: 0;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const Area = styled.div<{ $defaultPaddings?: boolean; $orientation: NavBarOrientation }>`
  ${({ $defaultPaddings, $orientation }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    ${$defaultPaddings &&
    $orientation === 'horizontal' &&
    css`
      padding: 0 16px;
    `}

    ${$defaultPaddings &&
    $orientation === 'vertical' &&
    css`
      padding: 16px 0;
    `}
  `}
`;

export { NavBar, MiddleArea, Area };
