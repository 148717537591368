import React from 'react';

import * as Styled from './CardBar.styles';
import { ICardBarProps } from './CardBar.types';

const CardBar = ({ bar, type, padding, size, border }: ICardBarProps) => (
  <>
    {bar && (
      <Styled.CardBar
        type={type}
        padding={padding}
        size={size}
        border={bar.border || border}
        onlyContent={!bar.title && !bar.description}
      >
        {(bar.title || bar.description) && (
          <Styled.TextColumn>
            {bar.title && <Styled.Title>{bar.title}</Styled.Title>}
            {bar.description && <Styled.Description>{bar.description}</Styled.Description>}
          </Styled.TextColumn>
        )}
        <Styled.ContentColumn onlyContent={!bar.title && !bar.description}>{bar.content}</Styled.ContentColumn>
      </Styled.CardBar>
    )}
  </>
);

export default CardBar;
