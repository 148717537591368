import styled, { css } from 'styled-components';

import { IDividerProps } from './Divider.types';

type DividerRequiredTypes = 'size' | 'direction' | 'intensity' | 'gutter';

const Divider = styled.hr<Required<Pick<IDividerProps, DividerRequiredTypes>>>`
  ${({ size, direction, intensity, gutter, theme }) => css`
    margin: 0;
    border: none;

    ${direction === 'horizontal' &&
    css`
      width: 100%;
      border-top: 2px solid ${theme.border[intensity]};

      ${size === 'small' &&
      css`
        border-top-width: 1px;
      `}

      ${size === 'medium' &&
      css`
        border-top-width: 2px;
      `}
      
      ${size === 'large' &&
      css`
        border-top-width: 4px;
      `}
      
      ${gutter === 'tight' &&
      css`
        margin: 24px 0;
      `}
      
      ${gutter === 'generous' &&
      css`
        margin: 56px 0;
      `}
    `}

    ${direction === 'vertical' &&
    css`
      height: 100%;
      border-left: 2px solid ${theme.border[intensity]};

      ${size === 'small' &&
      css`
        border-left-width: 1px;
      `}

      ${size === 'medium' &&
      css`
        border-left-width: 2px;
      `}
      
      ${size === 'large' &&
      css`
        border-left-width: 4px;
      `}
    `}
  `}
`;

export { Divider };
