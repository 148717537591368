import { FunctionComponent, ReactNode } from 'react';

import RCAccordion from 'rc-collapse';
import styled, { css } from 'styled-components';

import { accordionConsts } from './Accordion.consts';
import { IAccordionProps } from './Accordion.types';

const Accordion = styled(RCAccordion as unknown as FunctionComponent)<
  IAccordionProps & { expandIcon: (props: object) => ReactNode }
>`
  ${({ prefixCls, size, bodyPadding, fullWidth, nested, theme }) => css`
    transition: height 0.3s, opacity 0.3s;
    width: ${fullWidth ? '100%' : 'fit-content'};

    ${nested &&
    css`
      padding-left: 24px;
    `}

    .${prefixCls}-panel-header {
      ${size === 'small' && theme.label.medium.bold}
      ${size === 'medium' && theme.heading[4].bold}
      ${size === 'large' && theme.heading[3].demi}
      min-height: ${accordionConsts[size || 'medium'].minHeight}px;
    }

    .${prefixCls}-panel-content {
      ${bodyPadding &&
      css`
        padding: ${accordionConsts[size || 'medium'].bodyPadding};
      `}
    }

    .${prefixCls}-panel-item:last-of-type {
      border-bottom: none;
    }

    .${prefixCls}-panel-expand-icon {
      display: flex;
      margin-right: 6px;
    }
  `}
`;

export { Accordion };
